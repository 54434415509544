import React, { Component } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap';
import InputMet from './InputMet';

export default class AddItem extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            itemText4 :  this.props.currentState.itemText4,
            itemPrice1 : this.props.currentState.itemPrice1,
            itemPrice2 : this.props.currentState.itemPrice2,
            itemPrice3 : this.props.currentState.itemPrice3,
            itemPrice4 : this.props.currentState.itemPrice4,
        }
    }
    
    // INPUT
    handleChange = (event) => {

        this.setState( {[event.target.name] : event.target.value}, function() {
            this.props.InvoiceAddItems(this.state);
            //console.log(this.state);
        });
        
    }

    removeKey = () => {

        this.props.removeKey(this.props.idItem);

    }
    
    


    render() {

        let idItem =  this.props.idItem;

        let nameFromId = this.props.items.filter(function(desc) {
            return desc.id === idItem;
        });

        //console.log(nameFromId);

        return (
            <div className="row ItemsCont">

                {idItem ==='1' && !this.props.currentState.isKevaPay ? <div className="row">
                    <div className="col-md-6 divBoostrap col-sm-12">
                        <InputMet text='תקופת תשלום מ-' as='dateInput' value={this.props.currentState.invoiceDate1} name='invoiceDate1' submit={this.props.submit} />
                    </div>

                    <div className="col-md-6 divBoostrap col-sm-12">
                        <InputMet text='תקופת תשלום ל-' as='dateInput' value={this.props.currentState.invoiceDate2} name='invoiceDate2' submit={this.props.submit} />
                    </div>
                </div> : false }

                <div className="col-md-8 divBoostrap col-sm-12">

                {idItem !=='4' ? <InputGroup className="customInput">

                    {/* <InputGroup.Prepend className='inputPrependLabel '>
                        <InputGroup.Text>{nameFromId[0].name}</InputGroup.Text>
                    </InputGroup.Prepend> */}

                    <FormControl
                        disabled = {true}
                        //className='inputPrepend'
                        onChange={this.handleChange} 
                        name={'itemText' + idItem}
                        value={nameFromId[0].name}
                    />

                </InputGroup> :

                    

                        <InputGroup className="customInput">

                            <InputGroup.Prepend className='inputPrependLabel '>
                                <InputGroup.Text>פירוט</InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                className='inputPrepend'
                                onChange={this.handleChange} 
                                name={'itemText' + idItem}
                                value={this.state['itemText' + idItem]}
                            />

                        </InputGroup> }

                </div>

                <div className="col-md-3 divBoostrap col-sm-12">

                    <InputGroup className="customInput">

                        <InputGroup.Prepend className='inputPrependLabel '>
                            <InputGroup.Text>סכום</InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            className='inputPrepend'
                            onChange={this.state["itemPrice" + idItem] < 99999 ? this.handleChange : console.log('notChange')} 
                            name={"itemPrice" + idItem}
                            value={this.state["itemPrice" + idItem] > 0 ? this.state["itemPrice" + idItem] : '' }
                            placeholder = '0 ₪'
                            type = "number"
                            max={100}                            
                        />

                    </InputGroup>
                    
                </div>

                <div className="col-md-1 divBoostrap col-sm-12">
                    <button className="removeBtn" onClick={this.removeKey}>
                        <i className="far fa-trash-alt"></i>
                    </button>
                </div>
                
            </div>
        )
    }
}
