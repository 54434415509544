import React, { useState, useEffect } from 'react';
import { FormControl, Button } from 'react-bootstrap';

import { toast } from 'react-toastify' //npm i react-toastify


import getDataFromApi, { sendtoAdmin } from '../../../DinamicForm/newApi/getDataFromApi';
import loader from "../../../../img/preLoader.gif"
import convertDate from './../../../convertDateToDisplay'

import { map } from "lodash" //https://lodash.com/docs/4.17.15#map  //find
import {inputsBuilder} from './inputsBuilderBeforeAsama';
import { Grid } from '@material-ui/core';
import SignatureCanvas from 'react-signature-canvas';


export default function ReportBeforeAsama(props) {
  

    const { clientId, savedData, allSavedData, chosensocialWorker, visitDate } = props;

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);
    
    const [idFormData, setIdFormData] = useState(allSavedData?.id ? allSavedData.id : false);

    const [formData, setFormData] = useState(defaultValueForm(inputsBuilder,savedData,visitDate));
    
    // START SIGN
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    let sigPad = trimmedDataURL;
    const endSign = () => {setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png'));}
    const clear = () => {setTrimmedDataURL(null);sigPad.clear(sigPad);}


    const [trimmedDataURL1, setTrimmedDataURL1] = useState(null);
    let sigPad1 = trimmedDataURL1;
    const endSign1 = () => {setTrimmedDataURL1(sigPad1.getTrimmedCanvas().toDataURL('image/png'));}
    const clear1 = () => {setTrimmedDataURL1(null);sigPad1.clear(sigPad1);}
    
    // END SIGN
    
    useEffect(() => {

        const send = {
            clientId: clientId,
            socialWorkerId: chosensocialWorker
        };

        
        sendtoAdmin('newReportDataBeforeAsama', 'HomeVisits', send, setResponseData, setLoading);
        



    }, [clientId, savedData, chosensocialWorker]);


    const onChange = (e) => {

        //console.log(e.target.name, e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    

    //AUTO COMPLETE FROM DB
    useEffect(() => {

        if(responseData) {

            //console.log('A');
            if(!savedData) {
                setFormData({...formData, 
                
                    [inputsBuilder[1].name] : responseData?.firmData?.firmName,
                    [inputsBuilder[2].name] : responseData.socialWorker?.name,
                    [inputsBuilder[5].name] : responseData.clientData?.surname,
                    [inputsBuilder[6].name] : responseData.clientData?.name,
                    [inputsBuilder[7].name] : responseData.clientData?.tz,
                    [inputsBuilder[8].name] : convertDate(responseData.clientData?.birthday),
                    [inputsBuilder[9].name] : responseData.clientData?.sex,
                    [inputsBuilder[10].name] : responseData.clientData?.maritalStatus,
                    [inputsBuilder[15].name] : responseData.clientData?.religion,
                    [inputsBuilder[20].name] : responseData.clientData?.address,
                    [inputsBuilder[24].name] : responseData.clientData?.city,
                    [inputsBuilder[26].name] : responseData.clientData?.phone,
                    [inputsBuilder[27].name] : responseData.clientData?.phoneSMS,
                    [inputsBuilder[28].name] : responseData.clientData?.email,
                    
                });
            }

        }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseData])
    
    const submit = () => {
        

        const send = {

            idFormData: idFormData,
            formData: formData,
            clientId: clientId,
            socialWorkerId : chosensocialWorker,
            signClient: trimmedDataURL,
            signSocialWorker: trimmedDataURL1

        }

        //console.log(send);
        sendtoApi('saveUpdateBeforeAsama', 'HomeVisits', send, setLoading, setIdFormData);

    };

    console.log(allSavedData);
  
    return (
    <div className='ReportHomeSaveData reportBeforeAsama animate__animated animate__fadeIn' style={{margin: '20px 0 0 0'}}>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

        {responseData && <section className='sectionForm'>

            <h2>לשימוש פנימי</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[0].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[0].name}
                        value={formData[inputsBuilder[0].name]}
                    />
                </Grid>

                <Grid item md={5} >
                    <header>
                        <h3>{inputsBuilder[1].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[1].name}
                        value={formData[inputsBuilder[1].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[2].label}</h3>
                    </header>

                    <FormControl onChange={onChange}

                        //as="textarea"
                        name={inputsBuilder[2].name}
                        value={formData[inputsBuilder[2].name]}
                    />
                </Grid>

                <Grid item md={12} >
                    <header>
                        <h3>{inputsBuilder[3].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        /* as="textarea" */
                        name={inputsBuilder[3].name}
                        value={formData[inputsBuilder[3].name]}
                    />
                </Grid>

                <Grid item md={12} >
                    <header>
                        <h3>{inputsBuilder[4].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        /* as="textarea" */
                        name={inputsBuilder[4].name}
                        value={formData[inputsBuilder[4].name]}
                    />
                </Grid>

               

            </Grid>
            

            <h2>פרטים אישיים של המעסיק</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[5].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[5].name}
                        value={formData[inputsBuilder[5].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[6].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[6].name}
                        value={formData[inputsBuilder[6].name]}
                    />
                </Grid>


                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[7].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[7].name}
                        value={formData[inputsBuilder[7].name]}
                    />
                </Grid>


                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[8].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[8].name}
                        value={formData[inputsBuilder[8].name]}
                    />
                </Grid>


                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[9].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[9].name}
                        value={formData[inputsBuilder[9].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[9].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[10].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[10].name}
                        value={formData[inputsBuilder[10].name]}
                    >
                        <option value="">בחרו...</option>
                        
                        {responseData.clientData?.maritalStatus && <option value={responseData.clientData.maritalStatus}>{responseData.clientData.maritalStatus}</option>}

                        {map(inputsBuilder[10].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[11].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[11].name}
                        value={formData[inputsBuilder[11].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[12].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[12].name}
                        value={formData[inputsBuilder[12].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[13].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[13].name}
                        value={formData[inputsBuilder[13].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[14].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[14].name}
                        value={formData[inputsBuilder[14].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[15].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[15].name}
                        value={formData[inputsBuilder[15].name]}
                    >
                        <option value="">בחרו...</option>
                        
                        {map(inputsBuilder[15].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                {formData[inputsBuilder[15].name] === 'אחר' && <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[16].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[16].name}
                        value={formData[inputsBuilder[16].name]}
                    />
                </Grid> }
               

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[17].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[17].name}
                        value={formData[inputsBuilder[17].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[18].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[18].name}
                        value={formData[inputsBuilder[18].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[19].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[19].name}
                        value={formData[inputsBuilder[19].name]}
                    />
                </Grid>


            </Grid>

            <h2>כתובת המעסיק</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

                <Grid item md={6} >
                    <header>
                        <h3>{inputsBuilder[20].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[20].name}
                        value={formData[inputsBuilder[20].name]}
                    />
                </Grid>

                <Grid item md={2} >
                    <header>
                        <h3>{inputsBuilder[21].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[21].name}
                        value={formData[inputsBuilder[21].name]}
                    />
                </Grid>

                <Grid item md={2} >
                    <header>
                        <h3>{inputsBuilder[22].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[22].name}
                        value={formData[inputsBuilder[22].name]}
                    />
                </Grid>

                <Grid item md={2} >
                    <header>
                        <h3>{inputsBuilder[23].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[23].name}
                        value={formData[inputsBuilder[23].name]}
                    />
                </Grid>

                <Grid item md={6} >
                    <header>
                        <h3>{inputsBuilder[24].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[24].name}
                        value={formData[inputsBuilder[24].name]}
                    />
                </Grid>

                <Grid item md={2} >
                    <header>
                        <h3>{inputsBuilder[25].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[25].name}
                        value={formData[inputsBuilder[25].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[26].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[26].name}
                        value={formData[inputsBuilder[26].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[27].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[27].name}
                        value={formData[inputsBuilder[27].name]}
                    />
                </Grid>

                <Grid item md={8} >
                    <header>
                        <h3>{inputsBuilder[28].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[28].name}
                        value={formData[inputsBuilder[28].name]}
                    />
                </Grid>


            </Grid>

            <h2>הקשיש נמצא כעת ב:</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

                <Grid item md={12} >
                    <header>
                        <h3>{inputsBuilder[29].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[29].name}
                        value={formData[inputsBuilder[29].name]}
                    />
                </Grid>

                <Grid item md={6} >
                    <header>
                        <h3>{inputsBuilder[30].label}</h3>
                        <p>שם בית חולים</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[30].name}
                        value={formData[inputsBuilder[30].name]}
                    />
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[31].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[31].name}
                        value={formData[inputsBuilder[31].name]}
                    />
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[32].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[32].name}
                        value={formData[inputsBuilder[32].name]}
                    />
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[33].label}</h3>
                        <p>אצל:</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[33].name}
                        value={formData[inputsBuilder[33].name]}
                    />
                </Grid>

                <Grid item md={9} >
                    <header>
                        <h3>{inputsBuilder[34].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[34].name}
                        value={formData[inputsBuilder[34].name]}
                    />
                </Grid>

            </Grid>

            <h2>פרטים על בן/בת הזוג:</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[35].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[35].name}
                        value={formData[inputsBuilder[35].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[36].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[36].name}
                        value={formData[inputsBuilder[36].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[37].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[37].name}
                        value={formData[inputsBuilder[37].name]}
                    />
                </Grid>

                <Grid item md={12} >
                    <header>
                        <h3>{inputsBuilder[38].label}</h3>
                        <p>(גיל / מצב בריאותי/תפקודי/טיב היחסין/נזקק למטפל)</p>
                    </header>

                    <FormControl onChange={onChange}
                        as="textarea"
                        name={inputsBuilder[38].name}
                        value={formData[inputsBuilder[38].name]}
                    />
                </Grid>

            </Grid>

            <h2>איש קשר-בן/בת משפחה או אפוטרופוס</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[48].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[48].name}
                        value={formData[inputsBuilder[48].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[49].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[49].name}
                        value={formData[inputsBuilder[49].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[50].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[50].name}
                        value={formData[inputsBuilder[50].name]}
                    />
                </Grid>


                <Grid item md={6} >
                    <header>
                        <h3>{inputsBuilder[39].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[39].name}
                        value={formData[inputsBuilder[39].name]}
                    />
                </Grid>

                <Grid item md={2} >
                    <header>
                        <h3>{inputsBuilder[40].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[40].name}
                        value={formData[inputsBuilder[40].name]}
                    />
                </Grid>

                <Grid item md={2} >
                    <header>
                        <h3>{inputsBuilder[41].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[41].name}
                        value={formData[inputsBuilder[41].name]}
                    />
                </Grid>

                <Grid item md={2} >
                    <header>
                        <h3>{inputsBuilder[42].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[42].name}
                        value={formData[inputsBuilder[42].name]}
                    />
                </Grid>

                <Grid item md={6} >
                    <header>
                        <h3>{inputsBuilder[43].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[43].name}
                        value={formData[inputsBuilder[43].name]}
                    />
                </Grid>

                <Grid item md={2} >
                    <header>
                        <h3>{inputsBuilder[44].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[44].name}
                        value={formData[inputsBuilder[44].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[45].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[45].name}
                        value={formData[inputsBuilder[45].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[46].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[46].name}
                        value={formData[inputsBuilder[46].name]}
                    />
                </Grid>

                <Grid item md={8} >
                    <header>
                        <h3>{inputsBuilder[47].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[47].name}
                        value={formData[inputsBuilder[47].name]}
                    />
                </Grid>
            </Grid>


            <h2>אנשים הגרים עם המעסיק</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[51].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[51].name}
                        value={formData[inputsBuilder[51].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[52].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[52].name}
                        value={formData[inputsBuilder[52].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[53].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[53].name}
                        value={formData[inputsBuilder[53].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[54].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[54].name}
                        value={formData[inputsBuilder[54].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[55].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[55].name}
                        value={formData[inputsBuilder[55].name]}
                    />
                </Grid>


                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[56].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[56].name}
                        value={formData[inputsBuilder[56].name]}
                    />
                </Grid>

                
                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[57].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[57].name}
                        value={formData[inputsBuilder[57].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[58].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[58].name}
                        value={formData[inputsBuilder[58].name]}
                    />
                </Grid>


                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[59].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[59].name}
                        value={formData[inputsBuilder[59].name]}
                    />
                </Grid>

            </Grid>


            <h2>תיאור מצב המטופל</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >
                <Grid item md={12} >
                    <header>
                        <h3>{inputsBuilder[60].label}</h3>
                        <p>פרט</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[60].name}
                        value={formData[inputsBuilder[60].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[61].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[61].name}
                        value={formData[inputsBuilder[61].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[61].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={8} >
                    <header>
                        <h3>{inputsBuilder[62].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[62].name}
                        value={formData[inputsBuilder[62].name]}
                    />
                </Grid>


                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[63].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[63].name}
                        value={formData[inputsBuilder[63].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[63].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>


                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[64].label}</h3>
                        <p>ניידות</p>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[64].name}
                        value={formData[inputsBuilder[64].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[64].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={5} >
                    <header>
                        <h3>{inputsBuilder[65].label}</h3>
                        <p>ניידות</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[65].name}
                        value={formData[inputsBuilder[65].name]}
                    />
                </Grid>

                <Grid item md={12} >
                    <header>
                        <h3>{inputsBuilder[66].label}</h3>
                        {/* <p>ניידות</p> */}
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[66].name}
                        value={formData[inputsBuilder[66].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[66].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>


                {/* ---------------- group */}
                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[67].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[67].name}
                        value={formData[inputsBuilder[67].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[67].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                {formData[inputsBuilder[67].name] === 'אחר' && <Grid item md={9} >
                    <header>
                        <h3>{inputsBuilder[68].label}</h3>
                        <p>{inputsBuilder[67].label}</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[68].name}
                        value={formData[inputsBuilder[68].name]}
                    />
                </Grid> }
                {/* ---------------- end group */}


                {/* ---------------- group */}
                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[69].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[69].name}
                        value={formData[inputsBuilder[69].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[69].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                {formData[inputsBuilder[69].name] === 'אחר' && <Grid item md={9} >
                    <header>
                        <h3>{inputsBuilder[70].label}</h3>
                        <p>{inputsBuilder[69].label}</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[70].name}
                        value={formData[inputsBuilder[70].name]}
                    />
                </Grid> }
                {/* ---------------- end group */}



                {/* ---------------- group */}
                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[71].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[71].name}
                        value={formData[inputsBuilder[71].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[71].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                {formData[inputsBuilder[71].name] === 'אחר' && <Grid item md={9} >
                    <header>
                        <h3>{inputsBuilder[72].label}</h3>
                        <p>{inputsBuilder[71].label}</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[72].name}
                        value={formData[inputsBuilder[72].name]}
                    />
                </Grid> }
                {/* ---------------- end group */}


                {/* ---------------- group */}
                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[73].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[73].name}
                        value={formData[inputsBuilder[73].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[73].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                {formData[inputsBuilder[73].name] === 'אחר' && <Grid item md={9} >
                    <header>
                        <h3>{inputsBuilder[74].label}</h3>
                        <p>{inputsBuilder[73].label}</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[74].name}
                        value={formData[inputsBuilder[74].name]}
                    />
                </Grid> }
                {/* ---------------- end group */}

                
                {/* ---------------- group */}
                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[75].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[75].name}
                        value={formData[inputsBuilder[75].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[75].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                {formData[inputsBuilder[75].name] === 'אחר' && <Grid item md={9} >
                    <header>
                        <h3>{inputsBuilder[76].label}</h3>
                        <p>{inputsBuilder[75].label}</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[76].name}
                        value={formData[inputsBuilder[76].name]}
                    />
                </Grid> }
                {/* ---------------- end group */}
                            

            </Grid>

            <h2>תיאור מצב המעסיק ובן הזוג מההיבט התיפקודי הקוגניטיבי</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >
                <Grid item md={12} >
                    <header>
                        <h3>{inputsBuilder[77].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="textarea"
                        name={inputsBuilder[77].name}
                        value={formData[inputsBuilder[77].name]}
                    />
                </Grid>
            </Grid>

            <h2>מערכת תמיכה בלתי פורמלית (שכנים, מתנדבים וכו')</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >
                
                <Grid item md={12} >
                    <header>
                        <h3>{inputsBuilder[78].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[78].name}
                        value={formData[inputsBuilder[78].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[78].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[79].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[79].name}
                        value={formData[inputsBuilder[79].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[80].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[80].name}
                        value={formData[inputsBuilder[80].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[81].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[81].name}
                        value={formData[inputsBuilder[81].name]}
                    />
                </Grid>


                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[82].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[82].name}
                        value={formData[inputsBuilder[82].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[83].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[83].name}
                        value={formData[inputsBuilder[83].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[84].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[84].name}
                        value={formData[inputsBuilder[84].name]}
                    />
                </Grid>

            </Grid>

            <h2>שירותים תומכים בקהילה</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[85].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[85].name}
                        value={formData[inputsBuilder[85].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[85].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                {formData[inputsBuilder[85].name] === 'אחר' && <Grid item md={8} >
                    <header>
                        <h3>{inputsBuilder[86].label}</h3>
                        <p>{inputsBuilder[85].label}</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[86].name}
                        value={formData[inputsBuilder[86].name]}
                    />
                </Grid>}
                

            </Grid>

            <h2>מקורות הכנסה</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

                <Grid item md={6} >
                    <header>
                        <h3>{inputsBuilder[87].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[87].name}
                        value={formData[inputsBuilder[87].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[87].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                {formData[inputsBuilder[87].name] === 'אחר' && <Grid item md={6} >
                    <header>
                        <h3>{inputsBuilder[88].label}</h3>
                        <p>{inputsBuilder[87].label}</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[88].name}
                        value={formData[inputsBuilder[88].name]}
                    />
                </Grid>}
                

            </Grid>

            <h2>תיאור מצב סוציו אקונומי</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >
                <Grid item md={12} >
                    <header>
                        <h3>{inputsBuilder[89].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="textarea"
                        name={inputsBuilder[89].name}
                        value={formData[inputsBuilder[89].name]}
                    />
                </Grid>
            </Grid>

            
            <h2>מטפלים קודמים</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

                <Grid item md={2} >
                    <header>
                        <h3>{inputsBuilder[90].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[90].name}
                        value={formData[inputsBuilder[90].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[90].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                {formData[inputsBuilder[90].name] === 'כן' && <Grid item md={10} >
                    <header>
                        <h3>{inputsBuilder[91].label}</h3>
                        <p>{inputsBuilder[90].label}</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[91].name}
                        value={formData[inputsBuilder[91].name]}
                    />
                </Grid>}
                

            </Grid>

            <h2>פרופיל עובד זר מבוקש</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[92].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[92].name}
                        value={formData[inputsBuilder[92].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[92].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[93].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[93].name}
                        value={formData[inputsBuilder[93].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[94].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[94].name}
                        value={formData[inputsBuilder[94].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[95].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[95].name}
                        value={formData[inputsBuilder[95].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[96].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[96].name}
                        value={formData[inputsBuilder[96].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[97].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[97].name}
                        value={formData[inputsBuilder[97].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[98].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[98].name}
                        value={formData[inputsBuilder[98].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[99].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[99].name}
                        value={formData[inputsBuilder[99].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[100].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[100].name}
                        value={formData[inputsBuilder[100].name]}
                    />
                </Grid>

                <Grid item md={8} >
                    <header>
                        <h3>{inputsBuilder[101].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[101].name}
                        value={formData[inputsBuilder[101].name]}
                    />
                </Grid>

                <Grid item md={4} >
                    <header>
                        <h3>{inputsBuilder[102].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[102].name}
                        value={formData[inputsBuilder[102].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[102].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[103].label}</h3>
                        <p>תפקידי המטפל</p>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[103].name}
                        value={formData[inputsBuilder[103].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[103].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[104].label}</h3>
                        <p>תפקידי המטפל</p>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[104].name}
                        value={formData[inputsBuilder[104].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[104].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[105].label}</h3>
                        <p>תפקידי המטפל</p>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[105].name}
                        value={formData[inputsBuilder[105].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[105].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[106].label}</h3>
                        <p>תפקידי המטפל</p>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[106].name}
                        value={formData[inputsBuilder[106].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[106].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[107].label}</h3>
                        <p>תפקידי המטפל</p>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[107].name}
                        value={formData[inputsBuilder[107].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[107].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[108].label}</h3>
                        <p>תפקידי המטפל</p>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[108].name}
                        value={formData[inputsBuilder[108].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[108].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[109].label}</h3>
                        {/* <p>תפקידי המטפל</p> */}
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[109].name}
                        value={formData[inputsBuilder[109].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[109].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>


                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[110].label}</h3>
                        <p>תפקידי המטפל</p>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[110].name}
                        value={formData[inputsBuilder[110].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[110].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[111].label}</h3>
                        <p>תפקידי המטפל</p>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[111].name}
                        value={formData[inputsBuilder[111].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[111].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[112].label}</h3>
                        <p>תפקידי המטפל</p>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[112].name}
                        value={formData[inputsBuilder[112].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[112].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                <Grid item md={6} >
                    <header>
                        <h3>{inputsBuilder[113].label}</h3>
                        <p>תפקידי המטפל</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[113].name}
                        value={formData[inputsBuilder[113].name]}
                    />
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[114].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[114].name}
                        value={formData[inputsBuilder[114].name]}
                    />
                </Grid>

                <Grid item md={3} >
                    <header>
                        <h3>{inputsBuilder[115].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[115].name}
                        value={formData[inputsBuilder[115].name]}
                    >
                        <option value="">בחרו...</option>

                        {map(inputsBuilder[115].options, item => {
                            if (true) {
                                return < option value ={item.name } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>
                </Grid>

                {formData[inputsBuilder[115].name] === 'אחר' && <Grid item md={6} >
                    <header>
                        <h3>{inputsBuilder[116].label}</h3>
                        <p>{inputsBuilder[115].label}</p>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[116].name}
                        value={formData[inputsBuilder[116].name]}
                    />
                </Grid> }

                <Grid item md={12} >
                    <header>
                        <h3>{inputsBuilder[117].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="textarea"
                        name={inputsBuilder[117].name}
                        value={formData[inputsBuilder[117].name]}
                    />
                </Grid>

            </Grid>

            <h2>סיכום והמלצית העובד הסוציאלי</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >
                <Grid item md={12} >
                    <header>
                        <h3>{inputsBuilder[118].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        as="textarea"
                        name={inputsBuilder[118].name}
                        value={formData[inputsBuilder[118].name]}
                    />
                </Grid>
            </Grid>

            <h2>חתימות</h2>
            <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >
                <Grid item md={12} >
                    <header>
                        <h3>{inputsBuilder[119].label}</h3>
                    </header>

                    <FormControl onChange={onChange}
                        /* as="textarea" */
                        name={inputsBuilder[119].name}
                        value={formData[inputsBuilder[119].name]}
                    />
                </Grid>

                <Grid item md={6} >

                    <div className='signCont'>

                        <h3>חתימה {inputsBuilder[119].label}</h3>

                        {!allSavedData?.sign ? <>
                            <SignatureCanvas
                                ref={(ref) => { sigPad = ref }}
                                onEnd={endSign}
                                /* onBegin={onBegin} */
                                backgroundColor='white'
                                penColor='black'
                                canvasProps={{ width: 320, height: 200, className: 'sigCanvas' }}
                            />
                            <div>
                                <button onClick={clear}>מחיקת חתימה</button>
                            </div>
                        </> : <img src={allSavedData.sign} alt='חתימה' />}

                    </div>
                </Grid>

                <Grid item md={6} >

                    <div className='signCont'>

                        <h3>חתימת העובד הסוצאלי</h3>

                        {!allSavedData?.sign2 ? <>
                            <SignatureCanvas
                                ref={(ref) => { sigPad1 = ref }}
                                onEnd={endSign1}
                                /* onBegin={onBegin} */
                                backgroundColor='white'
                                penColor='black'
                                canvasProps={{ width: 320, height: 200, className: 'sigCanvas' }}
                            />
                            <div>
                                <button onClick={clear1}>מחיקת חתימה</button>
                            </div>
                        </> : <img src={allSavedData.sign2} alt='חתימה' />}

                    </div>
                </Grid>

            </Grid>

            <Button className='bounce1 sendBtn' onClick={submit} size="" variant="success" >שמירה</Button>

        </section>}

    </div>
  )



}


function defaultValueForm(inputsBuilder, savedData = false, visitDate) {

    let objReturn = {};

    //console.log(savedData);
      
    map(inputsBuilder, item => {
              
        objReturn[item.name] = savedData[item.name] ?  savedData[item.name] : item.defaultValue
        
      })
    
    if(!savedData) {

        objReturn.visitDate = convertDate(visitDate);
    }
    
      

    return objReturn;

}

function sendtoApi(url, controller, objectToSend, setLoading,setIdFormData = false ) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {
        
        setLoading(false);

        if (getData?.ok) {

            toast.success(getData.ok);

            if( setIdFormData && getData.insertId ) {

                setIdFormData(getData.insertId);

            }

        } else if (getData?.error) {


            toast.warning(getData.error);

        } else if (getData?.warning) {


            toast.warning(getData.warning);

        }  else {
            toast.error('שגיאה');
        }

    })

    getData.catch((error) => {
        setLoading(false);
        console.log(error)
    })

    /* getData.finally(() => {
        //scroll.scrollToBottom();
        setLoading(false)
    }) */
}
