import React, { Component } from 'react'

import { InputGroup, FormControl, Button } from 'react-bootstrap';
import InputMet from './InputMet';
import AddItem from './AddItem';
import PayOptions from './PayOptions'
import ShowInvoiceIdData from './ShowInvoiceIdData'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import InvoiceFinalSend from "./InvoiceFinalSend";
import BuiltOnlyMasInvoice from "./BuiltOnlyMasInvoice";

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */

function arrayRemove(arr, value) { 
    
    return arr.filter(function(ele){ 
        return ele !== value; 
    });
}

export default class FinanceIndex extends Component {

    constructor(props) {
        super(props)
    
        let today = new Date();

        let items = [
           
            {
                id: '1',
                name: 'דמי תאגיד'
                //name: 'חידוש התקשרות'
            },
            {
                id: '1b',
                name: 'דמי תאגיד בהוראת קבע'
            },
            {
                id: '2',
                name: 'דמי השמה'
                //name: 'עמלה כללי'
            },
            {
                id: '3',
                name: 'פתיחת תיק'
            },
            {
                id: '4',
                name: 'אחר'
            }
        ]

        //let test = {addInvoice : 4};

        this.state = {

            clientId : this.props.clientId,
            is_invoice : false,
            invoiceType : 'חשבונית מס/קבלה',
            invoiceFor : '',
            invoiceClient : this.props.dataClient.name + ' ' + this.props.dataClient.surname,
            invoicePhone : this.props.dataClient.phone,
            invoiceTz : this.props.dataClient.tz,
            invoiceAddress : this.props.dataClient.address,
            invoiceDate : new Date(today.toString().replace(/-/g, '/')),
            invoiceDate1 : new Date(today.toString().replace(/-/g, '/')),
            invoiceDate2 : new Date(today.toString().replace(/-/g, '/')),
            invoiceAddItem : '',
            invoiceItems : [],
            items : items,
            itemText4 : '',
            itemPrice1 : 0,
            itemPrice2 : 0,
            itemPrice3 : 0,
            itemPrice4 : 0,
            discount : 0,
            
            isKevaPay : false,

            SumValue : 0,
            discountValue: 0,
            taxesValue: 0,
            superTotal : this.props.superTotal ? this.props.superTotal : 0,
            editInvoice : this.props.editInvoice ? this.props.editInvoice : false,

            invoiceId : this.props.invoiceId ? this.props.invoiceId : false, // test // exist on DB,
            invoicePayed : false

        }
    }
    
    submit = (event) => {

        //console.log(event);

        this.setState(event,
            function(){
                //console.log(this.state);
            }
        );
        
    }
        
    // INPUT
    handleChangeInput = (event) => {
        this.setState({ [event.target.name]: event.target.value});
    }


    AddItemToList = (event) => {

        let currentArray = this.state.invoiceItems;

        const found = currentArray.find(element => element === event.target.value);


        if (event.target.value === '1b') {

            currentArray = [];
            this.setState({ invoiceItems : currentArray}, ()=> {

                currentArray.push('1');

                this.setState({ invoiceItems : currentArray, invoiceAddItem : '', isKevaPay : true }, function(){
                    console.log(this.state);
                });


            });

        } else if( found === undefined && !this.state.isKevaPay ) {
            
            //console.log(event.target.value);
            currentArray.push(event.target.value);

            this.setState({ invoiceItems : currentArray, invoiceAddItem : ''}, function(){
                //console.log(this.state.invoiceItems);
            });

        }

    }


    removeKey = (removeId) => {

        //console.log(removeId);
        let currentArray = this.state.invoiceItems;

        if(currentArray.length === 1) {

            this.setState({
                invoiceItems : [],
                isKevaPay : false,
                ['itemPrice' + removeId] : 0
            });

        } else {
            let result = arrayRemove(currentArray, removeId);
            //currentArray.splice(removeKey,1);
            this.setState({
                invoiceItems : result,
                isKevaPay : false,
                ['itemPrice' + removeId] : 0
            });
        }

    }
    
    InvoiceAddItems = (state) => {

        //console.log(state);

        this.setState({

            itemText4 : state.itemText4,
            itemPrice1 : state.itemPrice1,
            itemPrice2 : state.itemPrice2,
            itemPrice3 : state.itemPrice3,
            itemPrice4 : state.itemPrice4

        }, function(){
            //console.log(this.state);
        });
        
        //this.props.submit( {[event.target.name] : event.target.value});
        //console.log(this.state.inputValue);
    }

    sumAll = (action) => {

        let Sum =   parseFloat(this.state.itemPrice1) +
                    parseFloat(this.state.itemPrice2) +
                    parseFloat(this.state.itemPrice3) +
                    parseFloat(this.state.itemPrice4);

        let discount = 0;
        let returnPrice = Sum; 
        
        if(this.state.discount > 0) {
            discount = ( (Sum * parseFloat(this.state.discount)) / 100 );
        } 

        let taxes = ( ( (Sum - discount) * 17 ) / 100 );
        let superTotal = Sum - discount + taxes;
        let afterDiscount = Sum - discount;

        if (action === 'afterDiscount') {
            returnPrice = afterDiscount;
        }

        else if (action === 'taxex') {
            returnPrice = taxes;
        }

        else if (action === 'superTotal') {
            returnPrice = superTotal;
        }

        if ( this.state.superTotal !== superTotal ) {

            setTimeout(() => this.setState({

                SumValue : parseFloat(Sum),
                discountValue: parseFloat(afterDiscount),
                taxesValue: parseFloat(taxes),
                superTotal : parseFloat(superTotal)
        
            }, function(){
                //console.log(this.state);
            }), 300); 

        }

        return returnPrice.toFixed(2);
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        //console.log('IN');

        //let objectToSend = {hello : '1'}

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData }));
    }


    addInvoice = () => {
        
        let invoiceData = this.state;

        let sendData = {
            
            isKevaPay : invoiceData.isKevaPay,
            clientId : invoiceData.clientId,
            invoiceType : invoiceData.invoiceType,
            SumValue : invoiceData.SumValue,
            superTotal : invoiceData.superTotal,
            taxesValue : invoiceData.taxesValue,
            discount : invoiceData.discount,
            discountValue : invoiceData.discountValue,
            invoiceAddress : invoiceData.invoiceAddress,
            invoiceClient : invoiceData.invoiceClient,
            invoiceDate : invoiceData.invoiceDate,
            invoiceDate1 : invoiceData.invoiceDate1,
            invoiceDate2 : invoiceData.invoiceDate2,
            invoiceFor : invoiceData.invoiceFor,
            invoiceTz : invoiceData.invoiceTz,
            invoicePhone : invoiceData.invoicePhone,
            itemPrice1 : invoiceData.itemPrice1,
            itemPrice2 : invoiceData.itemPrice2,
            itemPrice3 : invoiceData.itemPrice3,
            itemPrice4 : invoiceData.itemPrice4,
            itemText4 : invoiceData.itemText4
        }

        this.sendtoAdmin('addInvoice','pays', sendData,'invoiceId');

        scroll.scrollMore(100);
    }

    closeInvoice = (params) => {
        
        this.setState({ invoicePayed : true });
        
        
    }
    

    render() {

        //console.log(this.props.dataClient);

        let addInvoiceFlag = this.state.SumValue && this.state.invoiceFor && this.state.invoiceClient && this.state.invoiceAddress &&
                            this.state.invoicePhone && ( this.state.itemPrice1 || this.state.itemPrice2 || this.state.itemPrice3 || this.state.itemPrice4 );
                            //this.state.itemPrice4

        return (
            <div className='FinanceIndex'>
                
                { !this.state.invoiceId.addInvoice ? <section className="invoices" >

                    <h2>חשבונית</h2>

                    <div className="row dataPay">

                        <div className="col-md-12 divBoostrap col-sm-12">

                            <InputGroup className="customInput">
                                <InputGroup.Prepend className='inputPrependLabel '>
                                    <InputGroup.Text>בחר סוג חשבונית</InputGroup.Text>
                                </InputGroup.Prepend>
                                
                                <FormControl
                                    as='select'
                                    /* 'inputPrepend customSelect' */
                                    className='inputPrepend customSelect'
                                    onChange={this.handleChangeInput} 
                                    value={this.state.invoiceType}
                                    name="invoiceType"
                                >

                                <option value='חשבונית מס/קבלה'>חשבונית מס/קבלה</option>
                                <option value='חשבונית מס'>חשבונית מס</option>
                                
                                </FormControl>

                        </InputGroup>

                        </div>

                        <div className="col-md-6 divBoostrap col-sm-12">
                           <InputMet className='inputBorderGrey' text='עבור מי החשבונית' as={false} value={this.state.invoiceFor} name='invoiceFor' submit={this.submit} />
                        </div>

                        <div className="col-md-6 divBoostrap col-sm-12">
                           <InputMet text='לכבוד' as={false} value={this.state.invoiceClient} name='invoiceClient' submit={this.submit} />
                        </div>

                        <div className="col-md-12 divBoostrap col-sm-12">
                           <InputMet text='כתובת' as={false} value={this.state.invoiceAddress} name='invoiceAddress' submit={this.submit} />
                        </div>

                        <div className="col-md-6 divBoostrap col-sm-12">
                           <InputMet text='ת.ז/ח.פ/דרכון' as={false} value={this.state.invoiceTz} name='invoiceTz' submit={this.submit} />
                        </div>

                        <div className="col-md-6 divBoostrap col-sm-12">
                           <InputMet text='טלפון' as={false} value={this.state.invoicePhone} name='invoicePhone' submit={this.submit} />
                        </div>

                        <div className="col-md-4 divBoostrap col-sm-12 hidden">
                           <InputMet text='תאריך' disabled={true} as='dateInput' value={this.state.invoiceDate} name='invoiceDate' submit={this.submit} />
                        </div>

                        

                        
                            
                        <div className="col-md-12 divBoostrap col-sm-12">

                            <InputGroup className="customInput borderColorGreen" >
                                <InputGroup.Prepend className='inputPrependLabel '>
                                    <InputGroup.Text>בחירת פריט</InputGroup.Text>
                                </InputGroup.Prepend>
                                
                                <FormControl
                                    as='select'
                                    /* 'inputPrepend customSelect' */
                                    className='inputPrepend customSelect'
                                    onChange={this.AddItemToList} 
                                    value={this.state.invoiceAddItem}
                                    name="invoiceAddItem"
                                >

                                <option value="">בחירת פריט...</option>
                                
                                {this.state.items.map(item => { if (true) {
                                    return <option key = {item.id} value={item.id}>{item.name}</option>
                                } else return false}) }
                                        
                                </FormControl>

                            </InputGroup>

                        </div>
                        
                        { this.state.isKevaPay && <h2 className='kevaTit animate__animated animate__bounce'>דמי תאגיד בהוראת קבע</h2> }

                        {this.state.invoiceItems[0] ? <div className="itemsList">
                            
                            {this.state.invoiceItems.map(item => { if (true) {
                                            /* currentState={this.state} */
                                            return <AddItem submit={this.submit} currentState = {this.state} items={this.state.items} InvoiceAddItems={this.InvoiceAddItems}  removeKey={ (removeKey)=>this.removeKey(removeKey) } key ={ item } idItem={item} />
                                        } else return false}) }
                            
                        </div> : false }

                        

                        <div className="row">

                            <div className='SumAll'>
                                <ul>

                                    <li>
                                        <span>סה"כ לפני מע"מ: </span> <span className="valueShow">{this.sumAll('beforeTaxes')} ₪</span>
                                    </li>

                                    <li>
                                        <InputMet text='הנחה:  (0-100%)' as={false} inputType={'number'} value={this.state.discount > 0 ? this.state.discount : '' } name='discount' submit={this.submit} />
                                    </li>

                                    <li className="">
                                        <span>סה"כ אחרי הנחה: </span> <span className="valueShow">{this.sumAll('afterDiscount')} ₪</span>
                                    </li>

                                    <li className="">
                                        <span>מע"מ (17%): </span> <span className="valueShow">{this.sumAll('taxex')} ₪</span>
                                    </li>

                                    <li className="superTotal">
                                        <strong>סה"כ לתשלום: </strong> <span className="valueShow">{this.sumAll('superTotal')} ₪</span>
                                    </li>

                                </ul>
                                        
                            </div>

                        </div>

                    

                </div>

                <div style={{textAlign : 'center'}}>
                    <Button className=""  onClick={ this.addInvoice } disabled={addInvoiceFlag ? false : true} variant={addInvoiceFlag ? 'success' : 'secondary' } size="lg" >אישור חשבונית</Button>
                </div>

                </section> : <div>
                        { !this.state.invoicePayed ? <ShowInvoiceIdData invoiceId={this.state.invoiceId} /> : false }
                </div> }


                {this.state.invoiceId.addInvoice && !this.state.invoicePayed ? <div className="AfterInvoiceId">

                    { this.state.superTotal > 0 && this.state.invoiceType === 'חשבונית מס/קבלה' ? <section className="PayOptions" >

                        <PayOptions superTotal={this.state.superTotal} editInvoice={this.state.editInvoice} currentState={this.state} closeInvoice={this.closeInvoice} />

                    </section> : false }

                    { this.state.superTotal > 0 && this.state.invoiceType === 'חשבונית מס' ? <section className="PayOptions" >
                            <BuiltOnlyMasInvoice editInvoice={this.state.editInvoice} invoiceId={this.state.invoiceId.addInvoice} closeInvoice={this.closeInvoice} />
                    </section> : false }

                </div> : false }
                

                { this.state.invoicePayed ? <section className="config" >

                        <h2 className='animate__animated animate__bounce insertOk' >{this.state.invoiceType === 'חשבונית מס/קבלה' ? 'החשבונית שולמה בהצלחה' : 'החשבונית נוצרה בהצלחה'}</h2>

                        <h2>אופן קבלת החשבונית: </h2>

                        <InvoiceFinalSend invoiceId={this.state.invoiceId.addInvoice} />

                        <div className="buttonCont">
                            <button className='btn btn-primary' onClick={(this.props.goBack)}>סגירת חלון</button>
                        </div>

                    </section> : false }

                        

            </div>
        )
    }
}
