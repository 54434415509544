import React, { useState, useEffect } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap';

import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi from '../../../DinamicForm/newApi/getDataFromApi';
import loader from "../../../../img/preLoader.gif"

import { RestUrls } from "../../../Urls";

export default function DigformsPDF(props) {

    const { clientId, workerId } = props;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let options = [];

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);

    const [formData, setFormData] = useState({
        formCode: 'בחר טופס',
    });

    const onChange = (e) => { 

        setResponseData(false);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          })

     }

    //console.log(formData);

    if(clientId) {

        options = [
            {
                formCode: 'בחר טופס',
                name: 'בחר טופס'
            },
            {
                formCode: 'employerCommitmentB',
                name: 'התחייבות מעסיק'
            },
            {
                formCode: 'waiverOfConfidentialityC',
                name: 'הצהרת ויתור על סודיות'
            },
            {
                formCode: 'rishumLishka',
                name: 'הצהרה של מעסיק המבקש להירשם בלשכה'
            },
            {
                formCode: 'clientReport',
                name: 'הצהרת מעסיק/ה בדבר העסקת עובד/ת במשק בית'
            },

            {
                formCode: 'commitment',
                name: 'כתב התחייבות למעסיק וללשכה פרטית המזמינים עובד זר מחו"ל- ענף הסיעוד'
            },

            {
                formCode: 'anotherFirm',
                name: 'מעבר לשכה - הצהרת מעסיק'
            }

            
        ]

    } else {

        options = [
            {
                formCode: 'בחר טופס',
                name: 'בחר טופס'
            },
            {
                formCode: 'matanAshratKnisaSugB',
                name: 'כניסה לישראל מסוג ב/ו סיעודי'
            },
            {
                formCode: 'bidud',
                name: 'הצהרת בידוד ללשכה'
            },
            
            {
                formCode: 'enter',
                name: 'הצהרת מעסיק לכניסת עובד לישראל'
            },
            {
                formCode: 'registration',
                name: 'הצהרה של עובד זר המבקש להירשם בלשכה'
            },
            {
                formCode: 'enter2',
                name: 'טופס בקשה למתן אשרת כניסה לישראל'
            },
            {
                formCode: 'placement',
                name: 'מכתב השמה'
            },
            {
                formCode: 'anotherFirm2',
                name: 'מעבר לשכה - הצהרת עוז'
            },
            /*{
                formCode: 'oz',
                name: 'הצהרת עוז הנרשם בלשכה'
            }, */
            /*{
                formCode: 'oz',
                name: 'הצהרת עוז הנרשם בלשכה'
            }, */
        ]

    }


    useEffect(() => {


        if ( (formData.formCode !== options[0].formCode) && !loading && !responseData) {

            const send = {

                id: clientId ? clientId : workerId,
                is_worker: workerId ? true : false,
                formCode: formData.formCode
            };

        
            //console.log('Sending...');
            sendtoApi('getPdflink', 'DigformsPDF', send, setLoading, setResponseData);
        }



    }, [formData,clientId,workerId,options,responseData,loading]);


  return (
    <div className="contSelect" style={{padding: '40px 20%'}}>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

        {/* <h2 className='blueH2'>בחר</h2> */}
        <InputGroup className="customInput" >

            <InputGroup.Prepend className='inputPrependLabel '>
                <InputGroup.Text>בחרו פעולה</InputGroup.Text>
            </InputGroup.Prepend>

            <FormControl
                as='select'
                /* 'inputPrepend customSelect' */
                className='inputPrepend customSelect'
                onChange={onChange} 
                value={formData.formCode}
                name="formCode"
            >

                {options.map(item => { if (true) {
                    /* currentState={this.state} */
                    return < option value ={item.formCode } key ={ item.formCode }> 
                                { item.name } 
                            </ option >
                } else return false}) }

            
            </FormControl>

        </InputGroup>

    </div>


  )
}


function sendtoApi(url, controller, objectToSend, setLoading, setResponseData) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

        if (getData.ok && getData.url) {

            //toast.success(getData.ok);
            window.open(RestUrls.baseApiUrl1 + 'wsign/complete_createPdf.php' + getData.url, '_blank')

        } else {
            toast.error('שגיאה');
            
        }

    })

    getData.catch((error) => {
        console.log(error)
    })

    getData.finally(() => {
        setLoading(false);
        setResponseData(true);
    })
}