import React, { Component } from 'react'
import convertDate from './../../../convertDateToDisplay'
import BtnDropdownDownload from'./BtnDropdownDownload'
import { Button } from 'react-bootstrap';
import BtnMoneyReturnCard from './BtnMoneyReturnCard'

function toNumberAndFix (number) {

    let newNumber = parseFloat(number);
    return newNumber.toFixed(2)

}

export default class ShowLinksInvoices extends Component {


    reloadInvoices = () => {
        
        if(this.props.reloadInvoices) {

            this.props.reloadInvoices()

        }
        
    }


    render() {
        
        let item = this.props.item;

        let flagPaysMas;
        let buttonText;

        if( item.invoiceType==='חשבונית מס' && !item.pdfTranzilaFileReceipt ) {
            flagPaysMas = true;
            buttonText = "ביצוע תשלום";
        } else {
            flagPaysMas = false;
        }

        let itemId = item.id;
        let superTotal = item.superTotal;

        //console.log(item);

        return (
            <li>{/* receipt_125.pdf */}
                <strong>{item.invoiceType} - {convertDate(item.invoiceDate)} </strong><span> | </span>
                <span>סכום: {toNumberAndFix(item.superTotal)} ₪</span>
                <div className="buttonsCont">
                    { item.pdfTranzilaFile ? <BtnDropdownDownload item={item} type='pdfInvoice' /> : false }
                    { item.pdfTranzilaFileReceipt ? <BtnDropdownDownload item={item} type='pdfReceipt' /> : false }
                    { item.are_card_pays ? <BtnMoneyReturnCard item={item} reloadInvoices = { () => this.reloadInvoices() } /> : false }
                    {flagPaysMas && this.props.editInvoiceId ? <Button className="doPaysForReceipt" size="sm" variant="dark" onClick={() => this.props.editInvoiceId(itemId,superTotal) } >{buttonText}</Button> : false }
                </div>
            </li>  
        )
    }
}
