//import {convertDate, convertDateToDisplay} from '../../../../Components/utils/helpFunctions'

//const today = new Date();
//let current_datetime = new Date(today.toString().replace(/-/g, '/'));

//console.log(current_datetime);

export const inputsBuilder = [

    {
        'name' : 'visitDate',
        'label' : 'תאריך הביקור',
        'defaultValue': '',
    },
    {
        'name' : 'firmName',
        'label' : 'שם הלשכה',
        'defaultValue': '',
    },
    {
        'name' : 'socialWorkerName',
        'label' : 'שם עובד סוציאלי',
        'defaultValue': '',
    },
    {
        'name' : 'visitPeople',
        'label' : 'נוכחו בביקור',
        'defaultValue': '',
        
    },
    {
        'name' : 'visitReason',
        'label' : 'סיבת הפניה',
        'defaultValue': '',
        
    },


    /* פרטים אישיים של המעסיק */
    {
        'name' : 'clientSurname',
        'label' : 'שם משפחה',
        'defaultValue': '',
        
    },
    {
        'name' : 'clientName',
        'label' : 'שם פרטי',
        'defaultValue': '',
        
    },
    {
        'name' : 'clientTz',
        'label' : 'תעודת זהות',
        'defaultValue': '',
        
    },
    {
        'name' : 'clientBirthday',
        'label' : 'תאריך לידה',
        'defaultValue': '',
        
    },
    {
        'name' : 'clientSex',
        'label' : 'מין',
        'defaultValue': '',
        'options' : 
            [
                {
                    'name' : 'זכר'
                },
                {
                    'name' : 'נקבה'
                }
            ],
    },
    {
        'name' : 'clientMaritalStatus',
        'label' : 'מצב משפחתי',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'רווק/ה'},
                {'name' : 'אלמן/ה'},
                {'name' : 'פרוד/ה'},
                {'name' : 'נשוי/אה'},
                {'name' : 'גרוש/ה'},
                {'name' : 'ידוע/ה בציבור'}
            ],
    },
    {
        'name' : 'clientCountry',
        'label' : 'ארץ מוצא',
        'defaultValue': '',
        
    },
    {
        'name' : 'clientAliya',
        'label' : 'שנת עליה',
        'defaultValue': '',
        
    },
    {
        'name' : 'clientWeight',
        'label' : 'משקל',
        'defaultValue': '',
        
    },
    {
        'name' : 'clientHeight',
        'label' : 'גובה',
        'defaultValue': '',
        
    },
    {
        'name' : 'clientReligion',
        'label' : 'זרם דתי',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'חרדי'},
                {'name' : 'דתי'},
                {'name' : 'מסורתי'},
                {'name' : 'חילוני'},
                {'name' : 'אחר'},
            ],
        
    },
    {
        'name' : 'clientReligionOther',
        'label' : 'זרם דתי אחר',
        'defaultValue': '',
        
    },

    {
        'name' : 'clientLanguajes1',
        'label' : 'שפות דיבור 1',
        'defaultValue': '',
        
    },

    {
        'name' : 'clientLanguajes2',
        'label' : 'שפות דיבור 2',
        'defaultValue': '',
        
    },

    {
        'name' : 'clientLanguajes3',
        'label' : 'שפות דיבור 3',
        'defaultValue': '',
        
    },


    //כתובת המעסיק
    {
        'name' : 'clientAddress',
        'label' : 'רחוב',
        'defaultValue': '',
        
    },
    {
        'name' : 'clientHomeNumber',
        'label' : 'מספר בית',
        'defaultValue': '',
        
    },
    {
        'name' : 'clientEnter',
        'label' : 'כניסה',
        'defaultValue': '',
        
    },
    {
        'name' : 'clientDira',
        'label' : 'דירה',
        'defaultValue': '',
        
    },

    {
        'name' : 'clientCity',
        'label' : 'ישוב',
        'defaultValue': '',
        
    },

    {
        'name' : 'clientPostal',
        'label' : 'מיקוד',
        'defaultValue': '',
        
    },

    {
        'name' : 'clientPhone2',
        'label' : 'טלפון קווי',
        'defaultValue': '',
        
    },

    {
        'name' : 'clientPhone1',
        'label' : 'טלפון נייד',
        'defaultValue': '',
        
    },

    {
        'name' : 'clientEmail',
        'label' : 'מייל',
        'defaultValue': '',
        
    },

    //הקשיש נמצא כעת ב:
    {
        'name' : 'seniorPlaceAddress',
        'label' : 'בכתובת הנ"ל',
        'defaultValue': '',        
    },
    {
        'name' : 'seniorPlaceHospital',
        'label' : 'בבית חולים: ',
        'defaultValue': '',        
    },
    {
        'name' : 'seniorPlaceHospital1',
        'label' : 'מחלקה: ',
        'defaultValue': '',        
    },
    {
        'name' : 'seniorPlaceHospital2',
        'label' : 'תאריך שחרור: ',
        'defaultValue': '',        
    },


    {
        'name' : 'seniorPlaceOther1',
        'label' : 'בכתובת אחרת',
        'defaultValue': '',        
    },

    {
        'name' : 'seniorPlaceOther2',
        'label' : 'כתובת',
        'defaultValue': '',        
    },


    //פרטים על בן/בת הזוג:

    {
        'name' : 'clientPartnerSurname',
        'label' : 'שם משפחה',
        'defaultValue': '',        
    },
    {
        'name' : 'clientPartnerName',
        'label' : 'שם פרטי',
        'defaultValue': '',        
    },
    {
        'name' : 'clientPartnerTz',
        'label' : 'מספר זהות',
        'defaultValue': '',        
    },
    {
        'name' : 'clientPartnerData',
        'label' : 'פרטים על בן/בת  הזוג',
        'defaultValue': '',        
    },


    //asdadsd
    //כתובת המעסיק
    {
        'name' : 'guardianAddress',
        'label' : 'רחוב',
        'defaultValue': '',
        
    },
    {
        'name' : 'guardianNumber',
        'label' : 'מספר בית',
        'defaultValue': '',
        
    },
    {
        'name' : 'guardianEnter',
        'label' : 'כניסה',
        'defaultValue': '',
        
    },
    {
        'name' : 'guardianDira',
        'label' : 'דירה',
        'defaultValue': '',
        
    },

    {
        'name' : 'guardianCity',
        'label' : 'ישוב',
        'defaultValue': '',
        
    },

    {
        'name' : 'guardianPostal',
        'label' : 'מיקוד',
        'defaultValue': '',
        
    },

    {
        'name' : 'guardianPhone2',
        'label' : 'טלפון קווי',
        'defaultValue': '',
        
    },

    {
        'name' : 'guardianPhone1',
        'label' : 'טלפון נייד',
        'defaultValue': '',
        
    },

    {
        'name' : 'guardianEmail',
        'label' : 'מייל',
        'defaultValue': '',
        
    },


    {
        'name' : 'guardianSurname',
        'label' : 'שם משפחה',
        'defaultValue': '',
        
    },

    {
        'name' : 'guardianName',
        'label' : 'שם פרטי',
        'defaultValue': '',
        
    },

    {
        'name' : 'guardianLink',
        'label' : 'הקשר לקשיש',
        'defaultValue': '',
        
    },


    //אנשים הגרים עם המעסיק
    {
        'name' : 'contactSurname1',
        'label' : 'שם משפחה',
        'defaultValue': '',        
    },
    {
        'name' : 'contacName1',
        'label' : 'שם פרטי',
        'defaultValue': '',        
    },
    {
        'name' : 'contactLink1',
        'label' : 'קירבה',
        'defaultValue': '',        
    },


    {
        'name' : 'contactSurname2',
        'label' : 'שם משפחה',
        'defaultValue': '',        
    },
    {
        'name' : 'contacName2',
        'label' : 'שם פרטי',
        'defaultValue': '',        
    },
    {
        'name' : 'contactLink2',
        'label' : 'קירבה',
        'defaultValue': '',        
    },



    {
        'name' : 'contactSurname3',
        'label' : 'שם משפחה',
        'defaultValue': '',        
    },
    {
        'name' : 'contacName3',
        'label' : 'שם פרטי',
        'defaultValue': '',        
    },
    {
        'name' : 'contactLink3',
        'label' : 'קירבה',
        'defaultValue': '',        
    },


    //תיאור מצב המטופל
    {
        'name' : 'seniorHealth',
        'label' : 'מצב בריאותי',
        'defaultValue': '',        
    },

    {
        'name' : 'seniorHealth1',
        'label' : 'מצב פסיכיאטרי',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'אין'},
                {'name' : 'יש'},
                {'name' : 'לא ידוע'},
                {'name' : 'לא אובחן'},
                {'name' : 'פרט'},
            ],
    },

    {
        'name' : 'seniorHealth1a',
        'label' : 'פרט',
        'defaultValue': '',        
    },

    {
        'name' : 'seniorHealth2',
        'label' : 'מצב קוגניטיבי',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'מתמצא בזמן במקום'},
                {'name' : 'ירידה קלה בהתמצאות'},
                {'name' : 'ירידה משמעותית בהתמצא'},
                {'name' : 'הפרעה בדיבור ובתפקוד'},
                {'name' : 'אין מידע'},
            ],
    },

    {
        'name' : 'seniorHealth3',
        'label' : 'מצב תפקודי',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'כסא גלגלים'},
                {'name' : 'הליכון'},
                {'name' : 'מרותק למיטה'},
                {'name' : 'מתהלך'},
            ],
    },

    {
        'name' : 'seniorHealth4',
        'label' : 'אחר',
        'defaultValue': '',        
    },


    {
        'name' : 'seniorHealth4a',
        'label' : 'שליטה על סוגרים',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'מלאה'},
                {'name' : 'חלקית'},
                {'name' : 'מוצרי ספיגה'},
                {'name' : 'אמצעי עזר (סטומה,קטטר)'},
            ],
    },


    {
        'name' : 'seniorHealth5',
        'label' : 'ראיה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'תקינה'},
                {'name' : 'חלשה'},
                {'name' : 'עיוור'},
                {'name' : 'אחר'},
            ],
    },

    {
        'name' : 'seniorHealth5a',
        'label' : 'אחר',
        'defaultValue': '',        
    },


    {
        'name' : 'seniorHealth6',
        'label' : 'שמיעה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'תקינה'},
                {'name' : 'חלשה'},
                {'name' : 'חרש'},
                {'name' : 'אחר'},
            ],
    },

    {
        'name' : 'seniorHealth6a',
        'label' : 'אחר',
        'defaultValue': '',        
    },


    {
        'name' : 'seniorHealth7',
        'label' : 'הלבשה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'עצמאי'},
                {'name' : 'זקוק לעזרה חלקית'},
                {'name' : 'זקוק לעזרה מלאה'},
                {'name' : 'אחר'},
            ],
    },

    {
        'name' : 'seniorHealth7a',
        'label' : 'אחר',
        'defaultValue': '',        
    },


    {
        'name' : 'seniorHealth8',
        'label' : 'אכילה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'עצמאי'},
                {'name' : 'זקוק לעזרה חלקית'},
                {'name' : 'זקוק לעזרה מלאה'},
                {'name' : 'אחר'},
            ],
    },

    {
        'name' : 'seniorHealth8a',
        'label' : 'אחר',
        'defaultValue': '',        
    },


    {
        'name' : 'seniorHealth9',
        'label' : 'רחיצה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'עצמאי'},
                {'name' : 'זקוק לעזרה חלקית'},
                {'name' : 'זקוק לעזרה מלאה'},
                {'name' : 'אחר'},
            ],
    },

    {
        'name' : 'seniorHealth9a',
        'label' : 'אחר',
        'defaultValue': '',        
    },

    //תיאור מצב המעסיק ובן הזוג מההיבט התיפקודי הקוגניטיבי
    {
        'name' : 'seniorHealth10',
        'label' : 'פרט',
        'defaultValue': '',        
    },

    //מערכת תמיכה בלתי פורמלית
    {
        'name' : 'seniorSupport1',
        'label' : 'מערכת תמיכה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'אין'},
                {'name' : 'יש'},
            ],
    },

    {
        'name' : 'seniorSupport2a',
        'label' : 'שם משפחה',
        'defaultValue': '',        
    },
    {
        'name' : 'seniorSupport2b',
        'label' : 'שם פרטי',
        'defaultValue': '',        
    },
    {
        'name' : 'seniorSupport2c',
        'label' : 'טלפון',
        'defaultValue': '',        
    },

    {
        'name' : 'seniorSupport3a',
        'label' : 'שם משפחה',
        'defaultValue': '',        
    },
    {
        'name' : 'seniorSupport3b',
        'label' : 'שם פרטי',
        'defaultValue': '',        
    },
    {
        'name' : 'seniorSupport3c',
        'label' : 'טלפון',
        'defaultValue': '',        
    },

    {
        'name' : 'seniorServiceSupport',
        'label' : 'שירותים',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'עוזרת בית'},
                {'name' : 'טיפול בית חוק סיעוד'},
                {'name' : 'אפוטרופוס'},
                {'name' : 'מרכז יום'},
                {'name' : 'קופת חולים'},
                {'name' : 'אחר'},
                
            ],
    },
    {
        'name' : 'seniorServiceSupport2',
        'label' : 'אחר',
        'defaultValue': '',        
    },

    
    //מקורות הכנסה

    {
        'name' : 'seniorMoney',
        'label' : 'מקורות הכנסה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'קצבת ביטוח לאומי ללא השלמת הכנסה'},
                {'name' : 'קצבת ביטוח לאומי עם השלמת הכנסה'},
                {'name' : 'פנסיה מהעבודה'},
                {'name' : 'רנטה, שילומים'},
                {'name' : 'אחר'},
            ],
    },
    {
        'name' : 'seniorMoney2',
        'label' : 'אחר',
        'defaultValue': '',        
    },


    //<h2>תיאור מצב סוציו אקונומי</h2>
    {
        'name' : 'seniorMoneyStatus',
        'label' : 'תיאור',
        'defaultValue': '',        
    },


     //מטפלים קודמים

     {
        'name' : 'seniorPrevWorkers',
        'label' : 'מטפלים קודמים',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'כן'},
                {'name' : 'לא'},
            ],
    },
    {
        'name' : 'seniorPrevWorkers2',
        'label' : 'סיבת עזיבה',
        'defaultValue': '',        
    },


    //פרופיל עובד זר מבוקש
    {
        'name' : 'seniorWants1',
        'label' : 'מין',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'זכר'},
                {'name' : 'נקבה'},
            ],
    },
    {
        'name' : 'seniorWants2',
        'label' : 'ארץ מוצא',
        'defaultValue': '',        
    },

    {
        'name' : 'seniorWants3',
        'label' : 'שפות 1',
        'defaultValue': '',        
    },

    {
        'name' : 'seniorWants4',
        'label' : 'שפות 2',
        'defaultValue': '',        
    },

    {
        'name' : 'seniorWants5',
        'label' : 'טווח גיל',
        'defaultValue': '',        
    },

    {
        'name' : 'seniorWants6',
        'label' : 'דת',
        'defaultValue': '',        
    },

    {
        'name' : 'seniorWants7',
        'label' : 'מצב משפחתי',
        'defaultValue': '',        
    },

    {
        'name' : 'seniorWants8',
        'label' : 'נתונים פיזים',
        'defaultValue': '',
    },

    {
        'name' : 'seniorWants9',
        'label' : 'נתונים אישיותיים',
        'defaultValue': '',
    },

    {
        'name' : 'seniorWants10',
        'label' : 'מיומניות עם כישורים מיוחדים נדרשים',
        'defaultValue': '',
    },

    {
        'name' : 'seniorWants11',
        'label' : 'רשיון נהיגה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'לא'},
                {'name' : 'כן'},
            ],
    },

    /* תפקידי המטפל */
    {
        'name' : 'seniorWorker1',
        'label' : 'ניקיון',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'לא'},
                {'name' : 'כן'},
            ],
    },
    {
        'name' : 'seniorWorker2',
        'label' : 'כביסה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'לא'},
                {'name' : 'כן'},
            ],
    },
    {
        'name' : 'seniorWorker3',
        'label' : 'הלבשה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'לא'},
                {'name' : 'כן'},
            ],
    },
    {
        'name' : 'seniorWorker4',
        'label' : 'רחצה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'לא'},
                {'name' : 'כן'},
            ],
    },
    {
        'name' : 'seniorWorker5',
        'label' : 'האכלה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'לא'},
                {'name' : 'כן'},
            ],
    },
    {
        'name' : 'seniorWorker6',
        'label' : 'החתלה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'לא'},
                {'name' : 'כן'},
            ],
    },
    {
        'name' : 'seniorWorker7',
        'label' : 'ליווי לטיפולים רפואיים',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'לא'},
                {'name' : 'כן'},
            ],
    },

    {
        'name' : 'seniorWorker8',
        'label' : 'השגחה',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'לא'},
                {'name' : 'כן'},
            ],
    },

    {
        'name' : 'seniorWorker10',
        'label' : 'מתן תרופות',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'לא'},
                {'name' : 'כן'},
            ],
    },

    {
        'name' : 'seniorWorker11',
        'label' : 'קניות/סידורים',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'לא'},
                {'name' : 'כן'},
            ],
    },

    {
        'name' : 'seniorWorker12',
        'label' : 'אחר',
        'defaultValue': '',
    },

    {
        'name' : 'seniorWorker13',
        'label' : 'יום חופשה מיועד לעובד הזר',
        'defaultValue': '',
    },

    {
        'name' : 'seniorWorker14',
        'label' : 'מיקום מיגורי העובד הזר',
        'defaultValue': '',
        'options' : 
            [
                {'name' : 'חדר פרטי'},
                {'name' : 'חדר עם המטופל'},
                {'name' : 'אחר'}
            ],
    },

    {
        'name' : 'seniorWorker15',
        'label' : 'אחר',
        'defaultValue': '',
    },

    {
        'name' : 'seniorWorker16',
        'label' : 'ציפיות מהעובד הזר',
        'defaultValue': '',
    },


    {
        'name' : 'seniorRecommends',
        'label' : 'סיכום והמלצות',
        'defaultValue': '',
    },


    {
        'name' : 'seniorSign',
        'label' : 'שם המעסיק / בן משפחה / אפוטרופוס',
        'defaultValue': '',
    },


]