import React, { Component } from 'react'
import InputMet from './InputMet';
import { Button,  InputGroup, FormControl, Modal, Alert } from 'react-bootstrap';

import convertDate from './../../../convertDateToDisplay'
import convertDateToDb from './../../../convertDateToDb'


import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import ShowPays from "./ShowPays";

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */

import loader from "../../../../img/preLoader.gif"

export default class PayOptions extends Component {

    constructor(props) {
        super(props)

        let today = new Date();
    
        this.state = {

            payTypeSelect : this.props.currentState.isKevaPay ? 'כרטיס אשראי' : '',
            
            bank : '',
            cash : '',
            checksList : [],

            isKevaPay : this.props.currentState.isKevaPay,

            checkDate : new Date(today.toString().replace(/-/g, '/')),
            checkBankName : '',
            checkBankSnif : '',
            checkBankAccount : '',
            checkNumber : '',
            checkSum : '',

            cardNumber : '',
            cardValidityMonth : '',
            cardValidityYear : '',
            cardCvv : '',
            cardTz : '',
            cardPaysCount : this.props.currentState.isKevaPay ? 1 : '',
            cardCash : this.props.currentState.isKevaPay ? this.props.currentState.superTotal : '',

            invoiceId : this.props.currentState.invoiceId.addInvoice ? this.props.currentState.invoiceId.addInvoice : false,
            clientId : this.props.currentState.clientId,

            payData : false,

            errorTotal : false,

            loading: false

        }
    }

    submit = (event) => {

        //console.log(event);

        this.setState(event,
            //function(){console.log(this.state)}
        );

    }


    addCheck = () => {

        let check = {
            checkDate : convertDateToDb(new Date(this.state.checkDate.toString().replace(/-/g, '/'))),
            checkBankName : this.state.checkBankName,
            checkBankSnif : this.state.checkBankSnif,
            checkBankAccount : this.state.checkBankAccount,
            checkNumber : this.state.checkNumber,
            checkSum : this.state.checkSum,
        }

        console.log(check);
        

        let newArray = this.state.checksList;

        newArray.push(check);

        let today = new Date();
        
        this.setState({

            checksList : newArray,
            checkDate : new Date(today.toString().replace(/-/g, '/')),
            //checkBankName : '',
            //checkBankSnif : '',
            //checkBankAccount : '',
            //checkSum : '',
            checkNumber : ''
            

        }, function(){
            console.log(this.state.checksList);
        });

        scroll.scrollToBottom();
    }

    removeCheck = (checkNumber) => {

        console.log(checkNumber);
        
        var i = 0;

        let arr = this.state.checksList;

        while (i < arr.length) {
            if (arr[i].checkNumber === checkNumber) {
            arr.splice(i, 1);
            } else {
            ++i;
            }
        }

        
        this.setState({

            checksList : arr
        
        }, function(){
            console.log(this.state.checksList);
        });
        
    }
    
    handleSelect = (event) => {

        
        this.setState({ [event.target.name]: event.target.value},function(){
            //console.log(this.state)
        });

        if(event.target.name === 'payTypeSelect') {

            scroll.scrollToBottom();
            //scroll.scrollMore(80);

        }
        

    }


    sendPay = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        //console.log('IN');

        //let objectToSend = {hello : '1'}

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);

        if(url === 'addPay') {
            
            getData.then(function(response) {return response;}).then(getData => this.setState({ [setStateName] : getData, loading : false }, function(){

                console.log(getData);

            } )  );

        } else {

            getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData }));

        }

        
    }

    clearPayData = () => {

        let today = new Date();

        this.setState({

            bank : '',
            cash : '',
            checksList : [],

            checkDate : new Date(today.toString().replace(/-/g, '/')),
            checkBankName : '',
            checkBankSnif : '',
            checkBankAccount : '',
            checkNumber : '',
            checkSum : '',
            cardCash : '',

            cardNumber : '',
            cardValidityMonth : '',
            cardValidityYear : '',
            cardCvv : '',
            cardTz : '',
            cardPaysCount : '', 
        });
    }
    


    addPay = (action) => {
        

        let paysData = this.state;
        let sendData = '';
        let totalCheck = 0;

        let supertotal = this.props.currentState.superTotal ? parseFloat(this.props.currentState.superTotal) : parseFloat(this.props.superTotal);

        if(paysData.checksList[0] ) {

            paysData.checksList.map(item => { if (true) {
                totalCheck = totalCheck + (parseFloat(item.checkSum));
                return true
            } else return false})

        }


        if (  paysData.bank + paysData.cash > supertotal) {
            console.log('yes');
            this.modalErrors(true);
            this.clearPayData();


        } else if ( paysData.cardCash > supertotal) {
                this.modalErrors(true);
                this.clearPayData();

        } else if ( totalCheck >  supertotal ) {

            this.modalErrors(true);
            this.clearPayData();

        } else {

            this.setState({ loading : true });

            if( action === 'cash' ) {

                sendData = {
                        bank : paysData.bank,
                        cash : paysData.cash,
                        invoiceId : paysData.invoiceId,
                        clientId : paysData.clientId
                    }
            }


            else if( action === 'card' ) {

                sendData = {

                        isKevaPay : paysData.isKevaPay,
                        invoiceId : paysData.invoiceId,
                        clientId : paysData.clientId,

                        cardNumber :  paysData.cardNumber,
                        cardValidityMonth : paysData.cardValidityMonth,
                        cardValidityYear :  paysData.cardValidityYear,
                        cardCvv :  paysData.cardCvv,
                        cardTz :  paysData.cardTz,
                        cardPaysCount :  paysData.cardPaysCount, 
                        cardCash :  paysData.cardCash
                    }
            }

            else if( action === 'check' ) {

                console.log(paysData.checksList);

                sendData = {
                        invoiceId : paysData.invoiceId,
                        clientId : paysData.clientId,

                        checksList : paysData.checksList
                    }
            }


            this.sendPay('addPay','pays', sendData,'payData');
            this.clearPayData();

            //cash
            //card
            //check
            //console.log(action);
        }

    }

    reLoadDataPay = () => {

        //console.log('reLoadDataPay');
        let paysData = this.state;
        let sendData = '';

        sendData = {
            invoiceId : paysData.invoiceId,
            clientId : paysData.clientId,
        }

        this.sendPay('getPays','pays', sendData,'payData');


        this.setState({ errorTotal : 'reset' });
        this.clearPayData();

    }
    
    
    is_payed = () => {
        
        console.log('is_payed');
        this.props.closeInvoice();

    }
    
    modalErrors = (value) => {
        this.setState({ modalShow : value })
    }

    componentDidMount() {

        if(this.props.editInvoice && !this.props.payData) {
            this.reLoadDataPay();
        }
    }
    

    render() {

        //console.log(this.state.invoiceId);
        
        //console.log(this.props);

        let counter = 0;

        let checkFlag = this.state.checkBankName && this.state.checkBankSnif && this.state.checkBankAccount &&
                        this.state.checkNumber && this.state.checkNumber && this.state.checkSum ? true : false;

        let cardFlag = this.state.cardNumber && this.state.cardValidityMonth && this.state.cardValidityYear &&
        this.state.cardCvv && this.state.cardTz && this.state.cardPaysCount && this.state.cardCash ? true : false;

        let cashFlag = this.state.cash;
        let cashFlag1 = this.state.bank;

        const month = [];
        const year = [];
        
        let number;

        for (var i = 1; i <= 12; i++) {

            if ( i >= 0 && i < 10 ) {number = '0' + i;} else {number = i;}
            month.push(<option value={number} key={i}>{number}</option>)

        };

        let currentYear = new Date().getFullYear().toString().substr(-2);

        currentYear = parseInt(currentYear);
        //console.log(currentYear);

        for (i = currentYear; i <= (currentYear + 10); i++) {
            if ( i >= 0 && i < 10 ) {number = '0' + i;} else {number = i;}
            year.push(<option value={number} key={i}>20{number}</option>)
        };

        return (
            <div className="PayOptions">

                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                {this.state.payData ? <div className="showPays">
                    <ShowPays editInvoice = {this.props.editInvoice} errorTotal = {this.state.errorTotal} currentState={this.state} reLoadData={()=>this.reLoadDataPay()} is_payed={this.is_payed} />
                </div> : false }

                {!this.state.payData ? <h2 className='animate__animated animate__bounce insertOk' >פרטי החשבונית התקבלו בהצלה:  </h2> : false }

                <div>

                {!this.state.isKevaPay && <>

                    <h2>אופן תשלום</h2>

                    <div className="PayOptionsCont">

                            
                        <div className="col-md-12 divBoostrap col-sm-12" style={{marginBottom : '30px'}}>

                            <InputGroup className="customInput">
                                <InputGroup.Prepend className='inputPrependLabel '>
                                    <InputGroup.Text>בחר אמצעי תשלום</InputGroup.Text>
                                </InputGroup.Prepend>
                                
                                <FormControl
                                    as='select'
                                    /* 'inputPrepend customSelect' */
                                    className='inputPrepend customSelect'
                                    onChange={this.handleSelect} 
                                    value={this.state.payTypeSelect}
                                    name="payTypeSelect"
                                >

                                    <option value="">בחר...</option>
                                    <option value="כרטיס אשראי">כרטיס אשראי</option>
                                    <option value="מזומן/העברה בנקאית">מזומן/העברה בנקאית</option>
                                    <option value="צ'קים">צ'קים</option>

                                </FormControl>

                            </InputGroup>
                        </div>
                    </div>
                </>}




                { this.state.payTypeSelect === 'מזומן/העברה בנקאית' ? <div>
                    
                        <div className="row cashInputs">
                            <div className="col-md-10 divBoostrap col-sm-12">
                                <InputMet text='מזומן' as={false} value={this.state.cash} placeholder='0 ₪' name='cash' submit={this.submit} />
                            </div>

                            <div className="col-md-2 divBoostrap col-sm-12">
                                <Button className=""  onClick={ () => this.addPay('cash') } size="" variant={cashFlag ? 'primary' : 'secondary' } disabled={cashFlag ? false : true} >אישור תשלום</Button>
                            </div>
                        </div>

                        <div className="row cashInputs">

                            <div className="col-md-10 divBoostrap col-sm-12">
                                <InputMet text='העברה בנקאית' as={false} value={this.state.bank} placeholder='0 ₪' name='bank' submit={this.submit} />
                            </div>

                            <div className="col-md-2 divBoostrap col-sm-12">
                                <Button className=""  onClick={ () => this.addPay('cash') } size="" variant={cashFlag1 ? 'primary' : 'secondary' } disabled={cashFlag1 ? false : true} >אישור תשלום</Button>
                            </div>

                        </div>

                    </div> : false }

                    { this.state.payTypeSelect === 'כרטיס אשראי' ? <div className="row">

                            <div className="creditCard">

                                    {this.state.payData && this.state.payData.creditCardErrorTranzila ? <Alert variant="danger">
                                        <p style={{fontSize: '16px', margin: '0 0 20px 0'}}>{this.state.payData.creditCardErrorTranzila}</p>
                                    </Alert> : false }

                                    <h3>כרטיס אשראי:</h3>

                                    <div className="row">


                                        <div className="col-md-6 divBoostrap col-sm-12">
                                            <InputMet text='מספר כרטיס' inputType={'number'} as={false} value={this.state.cardNumber} placeholder='' name='cardNumber' submit={this.submit} />
                                        </div>

                                        <div className="col-md-3 divBoostrap col-sm-12">
                                            <InputGroup className="customInput">
                                                <InputGroup.Prepend className='inputPrependLabel '>
                                                    <InputGroup.Text>תוקף שנה</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                
                                                <FormControl
                                                    as='select'
                                                    /* 'inputPrepend customSelect' */
                                                    className='inputPrepend customSelect'
                                                    onChange={this.handleSelect} 
                                                    value={this.state.cardValidityYear}
                                                    name="cardValidityYear"
                                                >

                                                
                                                <option value="">בחר...</option>
                                                
                                                {year}

                                                </FormControl>

                                            </InputGroup>
                                        </div>

                                        <div className="col-md-3 divBoostrap col-sm-12">
                                            <InputGroup className="customInput">
                                                <InputGroup.Prepend className='inputPrependLabel '>
                                                    <InputGroup.Text>תוקף חודש</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                
                                                <FormControl
                                                    as='select'
                                                    /* 'inputPrepend customSelect' */
                                                    className='inputPrepend customSelect'
                                                    onChange={this.handleSelect} 
                                                    value={this.state.cardValidityMonth}
                                                    name="cardValidityMonth"
                                                >

                                                
                                                <option value="">בחר...</option>
                                                
                                                {month}

                                                </FormControl>

                                            </InputGroup>
                                        </div>

                                        <div className="col-md-4 divBoostrap col-sm-12">
                                            <InputMet text='CVV' inputType={'number'} as={false} value={this.state.cardCvv} placeholder='' name='cardCvv' submit={this.submit} />
                                        </div>

                                        <div className="col-md-4 divBoostrap col-sm-12">
                                            <InputMet text='ת.ז' inputType={'number'} as={false} value={this.state.cardTz} placeholder='' name='cardTz' submit={this.submit} />
                                        </div>

                                        <div className="col-md-4 divBoostrap col-sm-12">
                                            <InputMet text='תשלומים' disabled={this.props.currentState.isKevaPay} inputType={'number'} as={false} value={this.state.cardPaysCount} placeholder='' name='cardPaysCount' submit={this.submit} />
                                        </div>
                                        
                                        <div className="col-md-12 divBoostrap col-sm-12">
                                            <InputMet text='סכום' inputType={'number'} as={false} disabled={this.props.currentState.isKevaPay} value={this.state.cardCash} placeholder='' name='cardCash' submit={this.submit} />
                                        </div>

                                        <div style={{textAlign : 'left'}}>
                                            <Button className=""  onClick={ () => this.addPay('card') } size="" variant={cardFlag && !this.state.loading ? 'primary' : 'secondary' } disabled={cardFlag && !this.state.loading ? false : true} >תשלום באשראי</Button>
                                        </div>

                                    </div>

                            </div>
                        </div> : false }

                        { this.state.payTypeSelect === "צ'קים" ? <div className="row">

                            <div className="Checks">
                                <h3>הוספת צ'קים:</h3>

                                <div className="row">

                                    <div className="col-md-4 divBoostrap col-sm-12">
                                        <InputMet text='תאריך' as={'dateInput'} value={this.state.checkDate} placeholder='' name='checkDate' submit={this.submit} />
                                    </div>

                                    <div className="col-md-4 divBoostrap col-sm-12">
                                        <InputMet text='מספר בנק' as={false} value={this.state.checkBankName} placeholder='' name='checkBankName' submit={this.submit} />
                                    </div>

                                    <div className="col-md-4 divBoostrap col-sm-12">
                                        <InputMet text='מספר סניף' as={false} value={this.state.checkBankSnif} placeholder='' name='checkBankSnif' submit={this.submit} />
                                    </div>

                                    <div className="col-md-4 divBoostrap col-sm-12">
                                        <InputMet text='מספר חשבון' as={false} value={this.state.checkBankAccount} placeholder='' name='checkBankAccount' submit={this.submit} />
                                    </div>

                                    <div className="col-md-4 divBoostrap col-sm-12">
                                        <InputMet text="מספר צ'ק" as={false} value={this.state.checkNumber} placeholder='' name='checkNumber' submit={this.submit} />
                                    </div>

                                    <div className="col-md-4 divBoostrap col-sm-12">
                                        <InputMet text="סכום"  as={false} inputType={'number'} value={this.state.checkSum} placeholder='' name='checkSum' submit={this.submit} />
                                    </div>

                                    <div style={{textAlign : 'left'}}>
                                        <Button className=""  onClick={ this.addCheck } size="" variant={checkFlag ? 'primary' : 'secondary' } disabled={checkFlag ? false : true} >הוספת צ'ק</Button>
                                    </div>


                                </div>


                                {this.state.checksList[0] ? <div className="checksList">
                                    <h4>צ'קים שהוספו:</h4>
                                    <ul>
                                        {this.state.checksList.map(item => { counter++; if (true) {
                                            return <li key = {counter} >
                                                    <strong>תאריך: </strong> <span>{convertDate(item.checkDate)}</span> <span> | </span> 
                                                    <strong>מספר בנק: </strong> <span>{item.checkBankName}</span> <span> | </span> 
                                                    <strong>מספר סניף: </strong> <span>{item.checkBankSnif}</span> <span> | </span> 
                                                    <strong>מספר חשבון: </strong> <span>{item.checkBankAccount}</span> <span> | </span> 
                                                    <strong>מספר צ'ק: </strong> <span>{item.checkNumber}</span> <span> | </span> 
                                                    <strong>סכום: </strong> <span>{item.checkSum}</span>
                                                    <Button className="" onClick={ () => this.removeCheck(item.checkNumber) } size="sm" variant='outline-danger' ><i className="far fa-trash-alt"></i></Button>
                                                </li>
                                        } else return false}) }

                                        <div style={{textAlign : 'left'}}>
                                            <Button className="sendChecks animate__animated animate__bounce"  onClick={ () => this.addPay('check') } size="sm" variant= 'success' >אישור רשימת צ'קים</Button>
                                        </div>

                                    </ul>
                                </div> : false }
                            </div>

                        </div> : false }

                </div>

                {/* https://react-bootstrap.netlify.app/components/modal/ */}
                <Modal
                    className='animate__animated animate__fadeInDown animate__faster modalErrors PaysError'
                    animation={false}
                    onHide={() => this.modalErrors(false)}
                    /* size="sm" // בגודל קטן!!! */
                    /* size="lg" // גדול!! */
                    // backdrop="static" // חייב ללחוץ על כפתור!
                    /* centered // VERTICA CENTER */
                    show={this.state.modalShow}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <span>שגיאה. נא למלא את השדות חובה</span>
                        </Modal.Title>
                        <Button onClick={() => this.modalErrors(false)} variant="secondary" >X</Button>
                    </Modal.Header>
                    <Modal.Body>

                        <h2>הוכנס סכום גדול יותר מהחשבונית</h2>

                        <div className="butoonsCont">
                            <Button className="closeModal" onClick={() => this.modalErrors(false)} variant="danger" >סגירה</Button>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
